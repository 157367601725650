import React from "react"
import styled from "styled-components"
import colors from "src/styles/colors"
import breakpoints from "src/styles/breakpoints"
import { Link } from "gatsby"

const BlockContainer = styled.div`
  min-height: 33vh;
  background: ${colors.royalBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  padding: 17px;
  width: 100%;
  max-width: 1600px;
`

const Heading = styled.h3`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: calc(0.12vw + 1rem);
`

const PressList = styled.div`
  padding-top: 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
`

const Press = styled.img`
  max-height: 42px;
  padding: 10px;
  margin: auto;

  @media (min-width: ${breakpoints.sm}) {
    max-height: 50px;
    padding: 12px;
  }
  @media (min-width: ${breakpoints.md}) {
    max-height: 60px;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-height: 70px;
    padding: 17px;
  }
`

const PressBlock = ({ heading, press, pressPageLink, color}) => {
  return (
    <BlockContainer >
      <Content>
        <Heading>{heading}</Heading>
        <Link to={pressPageLink.substring(22)}>
          <PressList>
            {press.map((image, index) => {
              return (
                <Press style={ index === 2 ? {maxWidth: "100px" }: {} }  
                  src={image.file.url} 
                  alt={image.title} 
                  key={index} />
              )
            })}
          </PressList>
        </Link>
      </Content>
    </BlockContainer>
  )
}

export default PressBlock
