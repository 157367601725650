import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"

const Content = styled.div`
   font-size: calc(0.6vw + 1rem);
   color: ${colors.royalBlue};

@media (min-width: 1600px}){
  font-size: 26px;
}

@media (max-width: ${breakpoints.md}){
  font-size: 1rem;
}
  p {
    margin: 1rem 0;
    line-height:122%;
  }

  li {
    margin: 0.5rem 0 0.5rem 30px;
  }

  a {
    color: ${colors.royalBlue};
  }

  i {
    font-style: italic;
  }

  b {
    ${fonts.workSansBold};
  }
`

const StyledOl = styled.ol`
  list-style-type: decimal;
  margin: 0.5rem 0;
`

const StyledUl = styled.ul`
  p {
    ::before {
      content: "\\2022";
      padding-right: 10px;
      display: inline-block;
      margin-left: -40px;
      min-width: 40px;
      text-align: right;
      box-sizing: border-box;
    }
  }
`

const Text = ({ content, references, style }) => {
  if (!content) return null
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        let assetId = node.data?.target?.sys?.id
        if (!assetId) return null
        if (!references) return null
        let image = null
        let description = ""
        for (const reference of references) {
          if (reference.contentful_id === assetId) {
            image = reference.gatsbyImageData
            description = reference.description
          }
        }
        return <GatsbyImage image={image} alt={description} />
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <StyledOl>{children}</StyledOl>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <StyledUl>{children}</StyledUl>
      },
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }
  return (
    <>
    {style ? <Content style={style}>{renderRichText(content, options)}</Content>
    :
      <Content>{renderRichText(content, options)}</Content>
      }
      </>
  )
}

export default Text
