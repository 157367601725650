import React from "react"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "../Blocks/TextImageBlock"
import HowItWorksBlock from "../Blocks/HowItWorksBlock"
import CollectBlock from "../Blocks/CollectBlock"
import TextVideoBlock from "../Blocks/TextVideoBlock"
import StickyCarouselBlock from "../Blocks/StickyCarouselBlock"
import HowItWorksInfoBlock from "../Blocks/HowItWorksInfoBlock"
import PartnersBlock from "../Blocks/PartnersBlock"
import FAQBlock from "../Blocks/FAQBlock"
import BenefitsBlock from "../Blocks/BenefitsBlock"
import DeliveryZone from "src/components/Index/DeliveryZone"
import ProductLandingPageBlock from "src/components/Index/ProductLandingPageBlock"
import ReviewBlock from "../LandingPage/ReviewBlock"
import PressBlock from "../Blocks/PressBlock"
import NewTextImageBlock from "../Blocks/NewTextImageBlock"
import BasicTextBlock from "../Blocks/BasicTextBlock"

const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
    
      {blocks && blocks.map((block, index) => {
        const { __typename: type } = block

        if (type === "ContentfulNewTextImageBlock") {
            return <NewTextImageBlock {...block} key={index}/>
        }

        if (type === "ContentfulPressBlock") {
          return <PressBlock {...block} key={index}  />
        }
        if (type === "ContentfulBasicTextBlock"){
            return <BasicTextBlock {...block} key={index} />
        }
        if (type === "ContentfulBenefitsBlock") {
          return <BenefitsBlock {...block} key={index}  />
        }
        if (type === "ContentfulTextVideoBlock") {
          return <TextVideoBlock {...block}></TextVideoBlock>
        }
        if (type === "ContentfulCollectBlock") {
          return <CollectBlock {...block} key={index} />
        }

        if (type === "ContentfulSimpleBlock") {
          return <SimpleBlock {...block} key={index} />
        }

        if (type === "ContentfulTextImageBlock") {
          return <TextImageBlock {...block} key={index} />
        }
      if (type === "ContentfulReviewBlock") {
            return <ReviewBlock {...block} blue={true} key={index}/>
        }


        if (type === "ContentfulPageBlockGroup") {
          return <PageBlockGroup {...block} key={index} />
        }

        if (type === "ContentfulHowItWorksBlock") {
          return <HowItWorksBlock {...block} key={index} />
        }

        if (type === "ContentfulStickyCarouselBlock") {
          return <StickyCarouselBlock {...block} key={index} />
        }
        
        if (type === "ContentfulHowItWorksInfoBlock") {
          return <HowItWorksInfoBlock {...block} key={index} />
        }

        if (type === "ContentfulPartnersBlock") {
          return <PartnersBlock {...block} key={index} />
        }

        if (type === "ContentfulZipCodeBlock")
          return <><DeliveryZone backgroundColor={"royalBlue"} {...block}></DeliveryZone></>
     

      })}

      

    </>
  )
}

export default Blocks
