import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import CtaButton from "../Core/CtaButton"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Link } from "gatsby"

import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "src/components/Layout/Layout"
import Text from "src/components/Blocks/Text"
import HeroText from "src/components/Blocks/AboutHeroText"

const TextDiv = styled.div`
    margin-top: 2em;
    margin-bottom: 2em;
    @media (max-width: ${breakpoints.md}) {
      margin-top: auto;
      margin-bottom: auto;
  }
`
const HeroBlock = styled.div`
  position: relative;
  ${'' /* max-width: 100vw; */}
  ${'' /* overflow: hidden; */}
 
    

`


const Button1 = styled(CtaButton)``
const StyledImage = styled(GatsbyImage)`
  height: 50vh;
  ${"" /* min-height: 500px; */}
  width: 100vw;

  @media (min-width: ${breakpoints.md}) {
    min-height: ${(props) => (props.lg ? "650px" : "500px")};
  }
`


const StyledDiv = styled.div`
background-color: ${colors.royalBlue};
height: 35vh;
  ${"" /* min-height: 500px; */}
  width: 100vw;

  @media (min-width: ${breakpoints.md}) {
    min-height: ${(props) => (props.lg ? "400px" : "400px")};
    height: 40vh;
  }

  @media (max-width: 480px) and (max-height: 670px){
    height: 45vh;
  }


`

const StyledVideo = styled.video`
  ${"" /* min-height: 500px; */}
  ${'' /* width: 100vw; */}
  height: 100vh;
 
  min-width: 100vw;
  object-fit: cover;
  @media (max-width: ${breakpoints.md} ) {

    
    position: relative;

  }

  @media (max-width: 650px ) {

  right: 100%;
  position: relative;

}

`

const HeroContentContainer = styled.div`
  position: absolute;
  width: 100%;
  ${'' /* height: 50%; */}
  z-index: 1;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6vw;
  text-align: center;
  color: ${colors.white};
  flex-wrap: wrap;
  padding-top: 55px;
  ${'' /* ${MOBILE_HEADER_HEIGHT}px; */}
  max-width: 1600px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
    width: 100%;
  }
`

const HeroContent = styled.div`
  width: 65%;
  font-size: 24px;
   position: absolute;
   top: 30%;
  @media (max-width: ${breakpoints.md}) {
    width: auto;
    position: auto;
    ${'' /* top: auto; */}
  }
`

const ButtonContainer = styled.div`
  ${"" /* margin-top: 34px; */}
`

const InternalLink = styled(Link)`
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  border-radius: 300px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: .4em 1.8em;
  ${'' /* font-size: calc((1 - 1) * 1.2vw + 1rem); */}
  cursor: pointer;
  display: inline-block;
  color: grey;
  font-family: 'Work Sans';
font-style: normal;
font-weight: 900;
font-size: calc(1vw + 0.5em);
line-height: 3vw;
  background-color: white;
  @media ( min-width: 1600px)
  {
    font-size: calc(16px + .5em);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 14px;
    position: relative;
  }
`

const Title = styled.h2`
font-family: 'Work Sans';
font-style: normal;
font-weight: 900;
color: #DCCB49;
${'' /* font-size: calc( 3vw + 1rem); */}
${'' /* @media (min-width: ${breakpoints.lg}) {
  
  font-size: 2em;

} */}
line-height: 108%;
/* identical to box height, or 82px */

letter-spacing: -0.02em;
text-transform: capitalize;
font-feature-settings: 'ordn' on, 'ss09' on, 'ss10' on, 'ss13' on;
`


const AboutHero = ({ heroData, lg = false }) => {

  const { title, content, video } = heroData

 
  let ctaLabel = heroData.ctaLabel ??  null
  let ctaUrl = heroData.ctaUrl ?? null
  let image = heroData.image ?? null


  return (
    <HeroBlock>
      {video ? (
        <StyledVideo autoPlay={true} playsInline={true} playsInline playsinline autoPlay muted loop>
          <source src={video.file.url} type="video/mp4" />
        </StyledVideo>
      ) : 
      ( image ? 
        <StyledImage
          image={image && image.gatsbyImageData}
          alt={image && image.description ? image.description : title}
          lg={lg ? 1 : 0}
        /> :  <StyledDiv
        //   image={image && image.gatsbyImageData}
        //   alt={image && image.description ? image.description : title}
          lg={lg ? 1 : 0}
        /> 
      )}

      <HeroContentContainer>
        <HeroContent>
         
            {title && <Title>{title}</Title>}
            {content && <TextDiv>
            <HeroText content={content} />
            </TextDiv>}
            {ctaLabel && ctaUrl && (
              <ButtonContainer>
                <InternalLink to={ctaUrl.substring(22)} >
                  {ctaLabel}
                </InternalLink>
              </ButtonContainer>
            )}
       
        </HeroContent>
      </HeroContentContainer>
    </HeroBlock>
  )
}

export default AboutHero
