import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "../Index/BenefitsText"
import { Link } from "gatsby"


const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  color: ${colors.royalBlue}
`

const Title = styled.h2`
  margin: 0 auto;
  padding: 30px;
 
  
`

const SubTitle = styled.p`
  margin: 0 auto;
  text-align: center;
  max-width: 800px;
  font-size: calc(0.6vw + 1rem);
  line-height: 142%;
  @media (max-width: ${breakpoints.md})
  {
    max-width: 80%;
  }
`
const InternalLink = styled(Link)`
  color: ${colors.royalBlue};
  text-decoration: underline;
  font-size: calc(0.6vw + 1rem);
  line-height: 142%;

`

const InnerContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

const OtherContentDiv = styled.div`
  width: 90%;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;

    width: auto;
  }
`
const ContentDiv = styled.div`
  width: 90%;
  float: right;
  text-align: right;

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    float: auto;
    width: auto;
  }
`
const RightCol = styled(Col)`
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 4%;

  @media (min-width: 1600px) {
    padding-right: 0px;
  }
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    padding-right: auto;
    padding-left: auto;
  }
`

const StyledRow = styled(Row)`
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 4vw;
  }
`

const LeftCol = styled(Col)`
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4%;
  text-align: left;
  @media (min-width: 1600px) {
    padding-left: 0px;
  }
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    padding-right: auto;
    padding-left: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    ${"" /* padding-right: auto */}
  }
`

const Image = styled(GatsbyImage)`
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 25px;
  border-radius: 50px;

  @media (min-width: 1600px) {
    margin-left: auto;
  margin-right: auto;
  }
  @media (max-width: ${breakpoints.md}) {
    text-align: center;

    justify-content: center;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 10vw;
  }
`

const SubHeading = styled.h4`
  margin-top: 15px;
  text-transform: uppercase;
  font-size: calc(1vw + 1rem);
  line-height: 100%;
  @media (min-width: 1600px}){
    font-size: 32px
  }
  color: ${colors.royalBlue};
  margin-right: 5vw;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    margin-right: auto;
    font-size: 15px;
  }
`

const LeftSubHeading = styled.h4`
  margin-top: 15px;
  text-transform: uppercase;
  font-size: calc(1vw + 1rem);
  line-height: 100%;
@media (min-width: 1600px}){
  font-size: 32px
}
  text-align: right;
  margin-left: 5vw;
  color: ${colors.royalBlue};

  @media (max-width: ${breakpoints.md}) {
    font-size: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const Heading = styled.h2`
  font-size: calc(2.4vw + 1rem);
  text-transform: uppercase;
  line-height: 100%;
  ${'' /* line-height: calc(2.3vw + 1rem); */}
  ${"" /* margin-right: 5vw; */}

  color: #824148;

  font-weight: 900px;
  @media (min-width: 1600px) {
    font-size: 55px;
  }
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
  }
`

const LeftHeading = styled.h2`
  text-transform: uppercase;
  font-size: calc(2.4vw + 1rem);
  text-align: right;
  ${"" /* margin-left: 5vw; */}
  line-height: 100%;
  ${'' /* line-height:calc( 2.3vw + 1rem ); */}
  color: ${colors.lightBlue};
  font-weight: 900;
  @media (min-width: 1600px){
    font-size: 55px;
  }
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    margin-left: auto;
  }
`

const TextContent = styled(Text)`
  line-height: 100%;
  color: ${colors.royalBlue};
`

const BlockContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
`

const TextImageBlockContainer = styled.div``

const BenefitsBlock = (props) => {
  const { homePageTextImageBlocks } = props
  const threeColors = ["#824148", "#319AD5", "#EB9CC3"]
  const textStyle = {}
  return (
    <BlockContainer>
      <InnerContainer>
      
      
    { props.heading && 
    <>
      <TitleWrapper>
      <Title>
        {props.heading}
      </Title>
      </TitleWrapper>
      <TitleWrapper>
      <SubTitle>
        {props.subHeading}
      </SubTitle>
      </TitleWrapper>
      </>
    }

      
    
      
        {homePageTextImageBlocks &&
          homePageTextImageBlocks.map((block, index) => {
            return (
              <>
                {index % 2 === 0 ? (
                  <TextImageBlockContainer>
                    <StyledRow>
                      <Col md={6} smAlignSelf="center">
                        <Image
                          image={block.media.gatsbyImageData}
                          alt={block.media.description || block.heading}
                        />
                      </Col>
                      <RightCol md={6} smAlignSelf="center">
                        <SubHeading>{block.subHeading}</SubHeading>
                        <Heading style={{ color: colors.pink }}>
                          {block.heading}
                        </Heading>
                        <OtherContentDiv>
                          <TextContent content={block.content} />
                          <InternalLink to={block.ctaLink}>
                            {" "}
                            {block.ctaLabel}{" "}
                          </InternalLink>
                        </OtherContentDiv>
                      </RightCol>
                    </StyledRow>
                  </TextImageBlockContainer>
                ) : (
                  <StyledRow>
                    <Col md={6} mdOrder="last" smAlignSelf="center">
                      <Image
                        image={block.media.gatsbyImageData}
                        alt={block.media.description || block.heading}
                      />
                    </Col>
                    <LeftCol md={6} smAlignSelf="center">
                      <LeftSubHeading>{block.subHeading}</LeftSubHeading>
                      <LeftHeading>{block.heading}</LeftHeading>
                      <ContentDiv>
                        <TextContent
                          style={{ lineHeight: "10px;" }}
                          content={block.content}
                        />
                        <InternalLink to={block.ctaLink}>
                          {" "}
                          {block.ctaLabel}{" "}
                        </InternalLink>
                      </ContentDiv>
                    </LeftCol>
                  </StyledRow>
                )}
              </>
            )
          })}
      </InnerContainer>
    </BlockContainer>
  )
}

export default BenefitsBlock
