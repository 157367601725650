import React, { useState } from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "./TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import Image from "src/components/Blocks/Image"
import { Col, Row } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "src/styles/breakpoints"
import Text from "../Blocks/AboutText"

const UnitTitle = styled.div`
  font-size: 14px;
  letter-spacing: initial;
  color: white;
`

const Break = styled.div`
  flex-basis: 100%;
  height: 0px;
`

const NewTextImageContainer = styled.div`
  height: auto;
  background-color: #F5F5F5;
`

const Heading = styled.h2`
  ${'' /* margin-left: 10vw;
  
  margin-right: 10vw; */}
 font-weight: 900;
  @media (min-width: 1600px){

    font-size: 60px;

  }

  font-size: 5vw;
  width: 80%;
  text-transform: uppercase;
  line-height: 4vw;
  color: #ffffff;
  line-height: 100%;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    font-size: 6vw;
    line-height: 6vw;
    width: auto;
}
`

const SubHeading = styled.h2`
  ${'' /* margin-left: 10vw;
  margin-right: 10vw; */}
  font-weight: 900;
  line-height: 100%;
  @media (min-width: 1600px){

    font-size: 40px;

}
font-size: 3vw;
  ${'' /* margin-top: 6vw; */}
  margin-top: 10%;
  text-transform: uppercase;
  color: #eb9cc3;
  line-height: 3vw;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    font-size: 4vw;
    margin-bottom: 6vw;
}
`

const Wrapper = styled.div`
    margin: 10%;
    margin-top: 10%;
`
const HeadingDiv = styled.div`
 text-align: left;
`

const LeftCol = styled(Col)`
  height: 100%;
`

const CoolRow = styled(Row)`
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
`
const RightCol = styled(Col)`
  height: 100%;
`
const NewTextImageBlock = (props) => {
 
  return (
    <NewTextImageContainer>
      <CoolRow>
        <Col md={6}>
          <Image image={props.image} alt={"Chef"} />
        </Col>
        <RightCol md={6}>
         <Wrapper>
        <Text content={props.content}/>
        </Wrapper> 
        </RightCol>
      </CoolRow>
    </NewTextImageContainer>
  )
}
export default NewTextImageBlock
