import React, { useState } from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "./TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import Image from "src/components/Blocks/Image"
import { Col, Row } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "src/styles/breakpoints"
import Text from "../Blocks/BasicTextBlockText"

const BasicTextBlockContainer = styled.div`
  background-color: ${colors.lightBlue};
  color: white;

`

const Wrapper = styled.div`
  background-color: ${colors.lightBlue};
  padding: 5%;
  @media (max-width: ${breakpoints.md}){
      padding: 10%;
  }
`

const BasicTextBlock = (props) => {
  return (
    <BasicTextBlockContainer>
    <Wrapper>
        <Text content={props.content} />
        </Wrapper>
    </BasicTextBlockContainer>
  )
}
export default BasicTextBlock
