import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blocks/AboutHero"
import Blocks from "../components/About/Blocks"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "../components/Layout/Layout"

const PageContainer = styled.div`
  @media (max-width: 897px) {
    margin-top: calc(4vmax + 94px);
  }

  margin-top: auto;
  @media (max-width: ${breakpoints.md}) {
    margin-top: calc(2vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const BlocksContainer = styled.div`
  background: white;
  margin-top: ${MOBILE_HEADER_HEIGHT}px;
  width: 100%;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${DESKTOP_HEADER_HEIGHT}px;
    flex-direction: row;
    ${"" /* padding-top: 3rem; */}
  }
`

const AboutPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  let videoURL
  if (page.hero && page.hero.video) {
    try {
      videoURL = page.hero.file.url
    } catch (e) {
      console.log(e)
    }
  }

  
  return (
    <Layout bg="#319AD5">
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <BlocksContainer>
          <Hero heroData={page.hero} lg />
          <Blocks blocks={page.blocks} />
        </BlocksContainer>
      </PageContainer>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage {
    contentfulPage(slug: { eq: "about" }) {
      hero {
        title
        content {
          raw
        }
      }
      blocks {
        __typename
        
        ... on ContentfulNewTextImageBlock {
            heading
            content {
              raw
            }
            image {
                description
                file {
                  url
                }
                gatsbyImageData
              }
              
        }
        ... on ContentfulBasicTextBlock {
            content { 
                raw
            }
        }
        ... on ContentfulBenefitsBlock {
          heading
          
          subHeading
          homePageTextImageBlocks {
            subHeading
            heading
            ctaLabel
            ctaLink
            content {
              raw
            }
            media {
              gatsbyImageData(width: 1200)
              description
              file {
                url
              }
            }
          }
        }
        ... on ContentfulReviewBlock {
          header
          text
          slide {
            headline
            description {
              raw
            }
            name
            image {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulPressBlock {
          heading
          press {
            title
            file {
              url
            }
          }
          pressPageLink
        }

    }
      metaDescription
      metaTitle
      title
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`
