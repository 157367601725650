import React, { Fragment } from "react"
import CtaButton from "../Core/CtaButton"
import Text from "./SmallText"
import Image from "./Image"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const EyebrowIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`

const Icon = styled.div`
  margin: 0 6px;
`

const PageBlock = (props) => {
  const { blockHeading, ctaUrl, ctaLabel, image, textBlocks, blockFooterText, eyebrowIcons } = props.block
  
  return (
    <div className={props.className}>
      {eyebrowIcons && eyebrowIcons.length > 0 ? (
        <EyebrowIcons>
          {eyebrowIcons.map((icon, index) => {
            return (
              <Icon key={index}>
                <GatsbyImage
                  key={index}
                  image={icon.gatsbyImageData}
                  alt={icon.description}
                />
              </Icon>
            )
          })}
        </EyebrowIcons>
      ) : 
      (<EyebrowIcons></EyebrowIcons>)
      }
      {blockHeading ? <h2>{blockHeading}</h2> : null}
      {image ? <Image image={image} /> : null}
      {textBlocks ? textBlocks.map((textBlock, index) => {
        const {heading, content} = textBlock
        return (
          <Fragment key={index}>
            {heading ? <h3>{heading}</h3> : null}
            {content ? <Text content={content} /> : null}
          </Fragment>
        )
      }) : null}
      {ctaUrl ? <CtaButton url={ctaUrl} label={ctaLabel ? ctaLabel : ctaUrl} /> : null}
      {blockFooterText ? <Text content={blockFooterText} /> : null}
    </div>
  )
}

export default PageBlock
